import React, { useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./quill.scss";

// Font size array and dynamic size injection
const fontSizeArr = [
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "28px",
  "32px",
  "36px",
  "48px",
];
const Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

// Font family array and dynamic family injection
const fontFamilyArr = [
  "Figtree, sans-serif",
  "sans-serif",
  "Arial",
  "Times New Roman",
  "Courier New",
  "Georgia",
  "Tahoma",
  "Verdana",
  "Helvetica",
];
const Font = Quill.import("attributors/style/font");
Font.whitelist = fontFamilyArr;
Quill.register(Font, true);

const injectCustomCSS = () => {
  const styleElement = document.createElement("style");
  const customCSS = `
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value=""]::before {
      content: "p";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value=""]::before {
      content: "p";
    }
    p {
      font-size: 18px; 
    }
   
  `;
  styleElement.innerHTML = customCSS;
  document.head.appendChild(styleElement);
};

const injectDynamicCSS = (sizes, families) => {
  const styleElement = document.createElement("style");
  let dynamicCSS = "";

  sizes.forEach((size) => {
    dynamicCSS += `
      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="${size}"]::before {
        content: "${size}";
      }
      .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="${size}"]::before {
        content: "${size}";
      }
    `;
  });

  families.forEach((family) => {
    dynamicCSS += `
      .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="${family}"]::before {
        content: "${family}";
        font-family: "${family}";
      }
      .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="${family}"]::before {
        content: "${family}";
        font-family: "${family}";
      }
    `;
  });

  styleElement.innerHTML = dynamicCSS;
  document.head.appendChild(styleElement);
};

const QuillEditor = ({ value, handleChange, placeholder, disabled }) => {
  useEffect(() => {
    Quill.register(Size, true);
    Quill.register(Font, true);
    injectDynamicCSS(fontSizeArr, fontFamilyArr);
    injectCustomCSS();
  }, []);

  return (
    <div>
      <ReactQuill
        value={value}
        onChange={(content, delta, source, editor) => {
          handleChange({
            target: { name: "description", value: content },
          });
        }}
        className="quill-editor"
        placeholder={placeholder}
        readOnly={disabled}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, ""] }], // Headers
            [{ size: fontSizeArr }], // Font size
            [{ font: fontFamilyArr }], // Font family
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
            [{ align: [] }, { direction: "rtl" }],
            [{ color: [] }, { background: [] }],
            ["blockquote", "code-block"],
            ["link", "image", "video"],
            ["clean"],
          ],
        }}
      />
    </div>
  );
};

export default QuillEditor;

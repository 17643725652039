import AWS from "aws-sdk";

// AWS Configuration
AWS.config.update({
  region: "ap-south-1",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

// Firehose sendData function
const sendDataToFirehose = (data) => {
  const firehose = new AWS.Firehose();
  const params = {
    DeliveryStreamName: "PUT-S3-Bt5i2",
    Record: {
      Data: JSON.stringify(data),
    },
  };

  firehose.putRecord(params, (err, result) => {
    if (err) {
      console.error("Error sending data to Firehose", err);
    } else {
      console.log("Data successfully sent to Firehose", result);
    }
  });
};

export default sendDataToFirehose;

import React from "react";
import { useSelector } from "react-redux";

import AddToggleList from "./AddToggleList/AddToggleList";
import AddRichText from "./AddRichText/AddRichText";
import AddButton from "./AddButton/AddButton";
import AddDivider from "./AddDivider/AddDivider";
import AddImage from "./AddImage/AddImage";
import AddWebBookmark from "./AddWebBookmark/AddWebBookmark";
import AddVideo from "./AddVideo/AddVideo";
import AddAudio from "./AddAudio/AddAudio";
import AddFile from "./AddFile/AddFile";
import AddGIF from "./AddGIF/AddGIF";
import AddMCQ from "./AddMCQ/AddMCQ";
import AddFillInTheBlanks from "./AddFillInTheBlanks/AddFillInTheBlanks";
import AddMatch from "./AddMatch/AddMatch";
import AddTextResponse from "./AddTextResponse/AddTextResponse";
import AddAudioRecording from "./AddAudioRecording/AddAudioRecording";
import AddVideoRecording from "./AddVideoRecording/AddVideoRecording";
import AddUploadFile from "./AddUploadFile/AddUploadFile";
import AddList from "./AddList/AddList";
import AddContainer from "./AddContainer/AddContainer";
import AddGrid from "./AddGrid/AddGrid";
import AddComicStrip from "./AddComicStrip/AddComicStrip";
import AddEmbedCode from "./AddEmbededCode/AddEmbedCode";
import AddDiscussion from "./AddDiscussion/AddDiscussion";
import AddPasteABlock from "./AddPasteABlock/AddPasteABlock";
import AddNativeEmbed from "./AddNativeEmbed/AddNativeEmbed";
import AddScormBlock from "./AddScormBlock/AddScormBlock";
import Box from "@mui/material/Box";
import AddBlockCarosal from "./AddBlockCarosal/AddBlockCarosal";
import AddFSForm from "./AddFSForm/AddFSForm";
import AddSurveyMonkey from "./AddSurveyMonkey/AddSurveyMonkey";
import AddGame from "./AddGame/AddGame";

export default function TabContents({
  setblockModOpen,
  courseDetails,
  setCourseBlocks,
  courseOne,
  blockModalOpen,
  activeTab,
}) {
  const savedCourseDetail = useSelector((state) => state.savedCourseDetail);
  let assessmentSettings = savedCourseDetail?.data?.assessmentDashboard;

  return (
    <Box>
      {activeTab === "Rich Text" && (
        <AddRichText
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          courseOne={courseOne}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Button" && (
        <AddButton
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Divider" && (
        <AddDivider
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Toggle List" && (
        <AddToggleList
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Images" && (
        <AddImage
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Web Bookmarks" && (
        <AddWebBookmark
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Video" && (
        <AddVideo
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Audio" && (
        <AddAudio
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "File" && (
        <AddFile
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "GIF" && (
        <AddGIF
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "MCQ" && (
        <AddMCQ
          assessmentSettings={assessmentSettings}
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Fill in the blanks" && (
        <AddFillInTheBlanks
          assessmentSettings={assessmentSettings}
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Match the following" && (
        <AddMatch
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Text Response" && (
        <AddTextResponse
          assessmentSettings={assessmentSettings}
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Audio Recording" && (
        <AddAudioRecording
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Video Recording" && (
        <AddVideoRecording
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Upload File" && (
        <AddUploadFile
          assessmentSettings={assessmentSettings}
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "FS Form" && (
        <AddFSForm
          assessmentSettings={assessmentSettings}
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Survey Monkey" && (
        <AddSurveyMonkey
          assessmentSettings={assessmentSettings}
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Game" && (
        <AddGame
          assessmentSettings={assessmentSettings}
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "SCORM" && (
        <AddScormBlock
          assessmentSettings={assessmentSettings}
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "List" && (
        <AddList
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Container" && (
        <AddContainer
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {/* added new container */}

      {activeTab === "Block Carousel" && (
        <AddBlockCarosal
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Grid" && (
        <AddGrid
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Comic Strip" && (
        <AddComicStrip
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Embeded Code" && (
        <AddEmbedCode
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "native-embed" && (
        <AddNativeEmbed
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Discussion" && (
        <AddDiscussion
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
      {activeTab === "Paste a block" && (
        <AddPasteABlock
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
        />
      )}
    </Box>
  );
}
